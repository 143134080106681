var msg: Translations = {
    DELETEALLPRODUCTSERROR: 'Error removing products from cart',
    ERR_CARRIERMISSING: 'You have to select a dispatch type first',
    ERR_CARRIER_COST_MISSING: 'No fee available for selected country carrier and order weight',
    ERR_CLIENTMANDATORYFIELDS: 'Fill in all mandatory fields',
    ERR_DATE_FROM: "Date must be after :from",
    ERR_DATE_FROM_TO: "Date must be between :from and :to",
    ERR_DATE_TO: "Date must be before :to",
    ERR_FILLEMAILFIELD: 'Fill in the email field',
    ERR_INVALIDMAIL: 'Invalid email address',
    ERR_LIGHTBOX_LOADING: 'Image detail is loading, try again in a few seconds',
    ERR_LOGININVALID: 'Invalid email or password',
    ERR_LOGINREQUIRED: 'Login required',
    ERR_MISSINGFIELDS: 'Fill in all mandatory fields',
    ERR_NOSIZESELECTED: 'You have to select a size',
    ERR_PEC_OR_SDI: 'Fill in at least one of PEC and SDI',
    ERR_PRIVACY: 'You have to accept privacy statement',
    ERR_USEREXISTS: 'User exists',
    ERR_VAT: 'Incorrect VAT format',
    ERR_VAT_PREFIX: 'VAT must begin with country code (e.g. IT, FR)',
    LABEL_ALREADYREGISTEREDUSERES: 'Already registered users',
    LABEL_ARTICLES: 'articles',
    LABEL_CANCEL: 'Cancel',
    LABEL_CONFIRM: 'Confirm',
    LABEL_INVITES_ALREADY_SENT: 'Users already invited',
    LABEL_NOTAPPLICABLE: 'Not applicable',
    LABEL_NOTSENTINVITES: 'Unsent invites',
    LABEL_SELECTALL: 'Select all',
    LABEL_SENTINVITES: 'Sent invites',
    LABEL_SUBMIT: 'Submit',
    LABEL_WARNING: 'Warning',
    MSG_ADDEDTOCART: 'Products added to your cart',
    MSG_ADDED_TO_WISHLIST: 'Product added to your wishlist',
    MSG_APPLIEDVATCHANGED: 'After billing data upgrade, VAT applied to your order changed. Check the new amount once page gets reloaded',
    MSG_COUPON_ERROR: 'The coupon code inserted is not valid or has been already burned',
    MSG_COUPON_VALID: 'The coupon code inserted has been accepted successfully',
    MSG_DEFERRED_DISPATCH_COMPUTATION: 'Only order booking mode will be enabled and dispatch fees will be sent you by email',
    MSG_DELETEALLPRODUCTS: 'Are you sure you want to remove all products from the cart?',
    MSG_EXTRAUELIMIT: 'You cannot check-out orders with a taxable less than 2000.00 &euro;',
    MSG_GENERIC_ERROR: 'Sorry, an error occurred',
    MSG_INSUFFICIENT_STOCK: 'Sorry, at the moment we don\'t have enough product availability',
    MSG_INVALID_CAPTCHA: 'Invalid captcha code',
    MSG_MAILSENT: 'E-Mails sent',
    MSG_MESSAGE_PRODUCTSHARE: 'Message',
    MSG_MESSAGE_SENT: 'Message sent successfully!',
    MSG_MIXED_CART: 'This product cannot be bought together with products you already have in your shopping cart because they are in different warehouses',
    MSG_MIXED_INTANGIBLE: 'This product cannot be bought together with products you already have in your shopping cart',
    MSG_MUST_ACCEPT_TERMS: 'You must accept the terms of use',
    MSG_NEWSLETTER_COMPLETED: 'Successful registration',
    MSG_PASSWORD_NOT_COMPLEX: 'Password must include at least 1 uppercase character, 1 lowercase character and 1 number and be at least 8 characters long',
    MSG_PASSWORD_NOT_EQUAL: 'Passwords don\'t match',
    MSG_PASSWORD_OK: 'Password changed correctly',
    MSG_PASSWORD_RESET: 'Password was succesfully reset',
    MSG_PAYMENT_PENDING: 'Payment pending',
    MSG_PRIVACY_POLICY: 'You must accept the privacy policy',
    MSG_PROFILECONSENT_SAVED: 'Your contact preferences have been updated',
    MSG_RECOVEREMAILSENT: 'Check your inbox and click on the link to confirm the change',
    MSG_RECOVEREMAILSENT_ERROR: 'Warning: you are not registered with this e-mail address or you are already resetting your password',
    MSG_REMOVED_FROM_WISHLIST: 'Product removed from your wishlist',
    MSG_RETURN_REQUEST: 'Return request created successfully',
    MSG_RETURN_REQUEST_ERROR: 'Return request could not be created, contact the admin',
    MSG_RETURN_REQUEST_SUCCESS: 'Return Request created successfully',
    MSG_RULE_REJECT: 'Product cannot be added to cart because of rule :rule',
    MSG_SAVE_SUCCESS: 'Save successful',
    MSG_SELECT_PROVINCE: "Select a province",
    MSG_SENDER_PRODUCTSHARE: 'Your name',
    MSG_SUBJECT_PRODUCTSHARE: ' shared something with you',
    MSG_SUBSCRIBED: 'Iscritto con successo',
    MSG_SUBSCRIPTION_FAILURE: 'Make sure you do not have any other active subscription',
    MSG_SUBSCRIPTION_NO_RENEWABLE: 'Too many days have passed so the subscription is no longer renewable',
    MSG_TOKEN_SENT: 'We have sent a verification code to your e-mail address.',
    MSG_USER_ALREADY_REGISTERED: 'User is already registered',
    MSG_USER_NOT_FOUND: 'User not found',
    MSG_WAIT: 'Wait please...',
    MSG_WRONG_PASSWORD: 'Wrong password'
}